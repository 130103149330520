
<template>
  <div
    v-if="model.coverMultipleItems && model.coverMultipleItems.length"
    class="layout-container mb-48 md:mb-64"
    :class="{ 'px-0 md:px-56': model.useSlider }"
  >
    <div v-if="isEpiEdit" class="mb-24">
      <div
        v-epi-edit="'UseSlider'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Use swiper in mobile
      </div>
    </div>
    <div
      v-if="model.title || model.linkText || isEpiEdit"
      class="relative mb-12 md:mb-16 md:flex justify-between items-start"
      :class="{
        'px-24 md:px-0': model.useSlider
      }"
    >
      <div
        v-if="model.title"
        v-epi-edit="'Title'"
        class="iShouldEpiEdit asH2 mb-0"
      >
        {{ model.title }}
      </div>
      <div
        v-else-if="isEpiEdit"
        v-epi-edit="'Title'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        + Add title
      </div>
      <GlobalsLinkHelper
        v-if="model.linkText"
        v-epi-edit="'linkText'"
        :to="model.linkUrl"
        class="arrow-link"
      >
        {{ model.linkText }}
      </GlobalsLinkHelper>
      <div
        v-else-if="isEpiEdit"
        v-epi-edit="'linkText'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        + Add link
      </div>
      <div
        v-if="isEpiEdit"
        v-epi-edit="'linkUrl'"
        class="absolute bottom-[-50px] right-0 btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Edit link
      </div>
    </div>
    <div v-if="!model.useSlider && isMobile">
      <div
        v-for="(coverItem, index) in model.coverMultipleItems"
        :key="index"
        class="w-full mb-12"
      >
        <div
          class="relative p-24 flex items-end bg-center bg-no-repeat bg-cover rounded-md sm:justify-start sm:items-end md:p-32"
          :class="{
            'aspect-16/7': coverItem.mobileImageRatio === '16:7',
            'aspect-4/3': coverItem.mobileImageRatio === '4:3',
            'aspect-3/4': coverItem.mobileImageRatio === '3:4',
            'aspect-square': coverItem.mobileImageRatio === '1:1',
            'md:aspect-16/7': coverItem.desktopImageRatio === '16:7',
            'md:aspect-4/3': coverItem.desktopImageRatio === '4:3',
            'md:aspect-3/4': coverItem.desktopImageRatio === '3:4',
            'md:aspect-square': coverItem.desktopImageRatio === '1:1',
          }"
          :style="
            `background-image: url(
              ${isMobile && coverItem.mobileImage ? coverItem.mobileImage : coverItem.desktopImage}
            )`
          "
        >
          <WysiwygWrapper
            v-if="coverItem.mainBody"
            v-epi-edit="'MainBody'"
            :style-config="{ 'wysiwyg': true }"
            :html="coverItem.mainBody"
          />
        </div>
      </div>
    </div>
    <client-only v-else>
      <swiper
        v-if="model.coverMultipleItems"
        v-epi-edit="'CoverMultipleItems'"
        :space-between="isMobile ? 12 : 24"
        :slides-per-view="isMobile ? 'auto' : model.coverMultipleItems.length"
        :no-swiping="true"
        :no-swiping-class="'swiper-no-swiping'"
        class="swiper-cover"
        :class="{
          'no-swiper-mobile swiper-no-swiping' : !model.useSlider && isMobile,
          'swiper-mobile' : model.useSlider,
          '!px-24 md:!px-0': model.useSlider,
        }"
      >
        <swiper-slide
          v-for="(coverItem, index) in model.coverMultipleItems"
          :key="index"
        >
          <div
            class="relative p-24 flex items-end bg-center bg-no-repeat bg-cover rounded-md sm:justify-start sm:items-end md:p-32"
            :class="{
              'aspect-16/7': coverItem.mobileImageRatio === '16:7',
              'aspect-4/3': coverItem.mobileImageRatio === '4:3',
              'aspect-3/4': coverItem.mobileImageRatio === '3:4',
              'aspect-square': coverItem.mobileImageRatio === '1:1',
              'md:aspect-16/7': coverItem.desktopImageRatio === '16:7',
              'md:aspect-4/3': coverItem.desktopImageRatio === '4:3',
              'md:aspect-3/4': coverItem.desktopImageRatio === '3:4',
              'md:aspect-square': coverItem.desktopImageRatio === '1:1',
            }"
            :style="
              `background-image: url(
                ${isMobile && coverItem.mobileImage ? coverItem.mobileImage : coverItem.desktopImage}
              )`
            "
          >
            <WysiwygWrapper
              v-if="coverItem.mainBody"
              v-epi-edit="'MainBody'"
              :style-config="{ 'wysiwyg': true }"
              :html="coverItem.mainBody"
            />
          </div>
        </swiper-slide>
      </swiper>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import type { CoverMultipleBlock } from '~/content-types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { usePageContentStore } from '~/store/pageContent';
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);
const { isEpiEdit } = storeToRefs(usePageContentStore());

defineProps<{
  model: CoverMultipleBlock
}>();

</script>

<style lang="postcss">
.swiper-cover.swiper-mobile .swiper-wrapper .swiper-slide {
  @media screen and (max-width: 767px) {
    width: 80% !important;
  }
}
</style>
